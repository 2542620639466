<template>
  <div class="card__flex card card-box mb-3">
    <div class="card-header pr-2">
      <div class="card-header--title">
        <small>{{ title }}</small>
      </div>
      <div class="card-header--actions">
        <slot name="header-actions"></slot>
      </div>
    </div>

    <div class="card-body p-0">
      <div class="h-100 d-flex flex-column justify-content-between">
        <loading v-if="isFetching" :message="loadingMessage" style="height: 310px" />

        <div v-show="!isFetching" class="h-100">
          <b-table
            ref="configTable"
            :busy.sync="isFetching"
            :items="itemData"
            :fields="fieldsConfig"
            hover
            responsive
            thead-class="thead-light"
            class="mb-0"
            v-bind="{ ...$attrs, ...$props }"
            v-on="{ ...$listeners }"
          >
            <template #head(actions)="row">
              <div class="text-center">
                <span>{{ row.label }}</span>
              </div>
            </template>

            <template #cell(actions)="{ item }">
              <div class="text-center">
                <toggle-button
                  color="#3d6b99"
                  :value="item.is_open"
                  @change="toggleVisible($event.value)"
                />
              </div>
            </template>

            <template v-if="showRefresh" #head(refresh)>
              <div class="text-center">
                <a
                  href="javascript:void(0)"
                  class="btn btn-sm text-primary btn-link pl-0"
                  v-b-tooltip.hover
                  title="Refresh"
                  @click.prevent="refresh"
                >
                  <font-awesome-icon :spin="isFetching" size="sm" icon="sync" />
                </a>
              </div>
            </template>
          </b-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import formatDateMixin from "../../mixins/formatDateMixin";
import Loading from "../misc/Loading";
import { ConfigTeachingDare } from "../../models";
import { ToggleButton } from "vue-js-toggle-button";

export default {
  mixins: [formatDateMixin],

  props: {
    title: String,
    fields: Array,
    params: Object,
  },

  components: {
    Loading,
    ToggleButton,
  },

  data() {
    return {
      isFetching: false,
      // currentPage: parseInt(this.$route.query.page) || 1,
      // numberOfPages: 1,
      // sortBy: "",
      // sortDesc: false,
      showRefresh: true,
      loadingMessage: "กำลังดาวน์โหลดข้อมูล",
      itemData: [],
      fieldsConfig: [
        { key: "name", label: "ตั้งค่า" },
        { key: 'actions', label: 'จัดการ' },
        { key: "refresh", label: "", thStyle: "width: 25px;" },
      ],
    };
  },

  watch: {
    params: {
      deep: true,
      handler: "onParamsChanged",
    },
  },

  methods: {
    onParamsChanged() {
      this.currentPage = 1;
      this.numberOfPages = 1;

      this.refresh();
    },

    async fetch() {
      let data = {}

      this.isFetching = true;

      try {
        const promise = await ConfigTeachingDare.api().findAll({ save: false });

        let { data: responseData = []} =
          promise.response.data;
        data = {
          ...responseData,
          name: "ตั้งค่าการลงแผนการสอน",
        };
        this.itemData.push(data);
      } catch (error) {
        console.log(error);
        this.$toast.error("ไม่สามารถดึงข้อมูลได้ กรุณาลองใหม่อีกครั้ง");
      } finally {
        this.isFetching = false;
      }

      return data;
    },

    async toggleVisible(value) {
      let promise;

      try {
        promise = await ConfigTeachingDare.api().update({
          is_open: value,
        });
        const { data } = promise.response;

        if (data && data.response_status == "SUCCESS") {
          this.$toast.success(`อัพเดตการตั้งค่าการลงแผนการสอนเรียบร้อยแล้ว`);
        } else {
          this.$toast.error(
            `อัพเดตการตั้งค่าการลงแผนการสอนไม่สำเร็จ กรุณาลองใหม่อีกครั้ง`
          );
        }
      } catch (error) {
        console.log(error);
        this.$toast.error("ไม่สามารถตั้งค่าการลงแผนการสอนได้ กรุณาลองใหม่อีกครั้ง");
      }

      this.refresh();
      // window.location.reload();
    },

    refresh() {
      this.$emit("before:refresh");

      this.$refs.configTable.refresh();

      this.$emit("after:refresh");
    },
  },

  created() {
    this.fetch();
  },
};
</script>
