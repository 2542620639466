<template>
  <page-content>
    <page-title heading="ตั้งค่าประกาศ">
      <template v-slot:right>
        <b-button variant="danger" @click="showModalAnnouncement = true"
          >เพิ่มประกาศ</b-button>
      </template>
    </page-title>

    <announcement-table
      ref="announcementTable"
      class="my-4"
      :fields="announcementFields"
      :params="announcementTableParams"
      @edit="onEdit"
      @delete="onDelete"
    />

    <create-or-update-announcement
      :edit-data.sync="editData"
      v-model="showModalAnnouncement"
      @create:success="onCreateSuccess"
    ></create-or-update-announcement>

    <delete-announcement
      v-model="showModalDelete"
      @delete:success="onDeleteSuccess"
      :delete-data.sync="deleteData"
    ></delete-announcement>
  </page-content>
</template>

<script>
import PageContent from "../../components/layout/PageContent";
import PageTitle from '../../components/layout/PageTitle';
import AnnouncementTable from "../../components/table/Announcement.vue";
import CreateOrUpdateAnnouncement from "../../components/modal/CreateOrUpdateAnnouncement.vue";
import DeleteAnnouncement from "../../components/modal/DeleteAnnouncement.vue";

export default {
  components: {
    PageContent,
    PageTitle,
    AnnouncementTable,
    CreateOrUpdateAnnouncement,
    DeleteAnnouncement,
  },

  data() {
    return {
      editData: {},
      announcementTableParams: {},
      deleteData: {},
      announcementFields: [
        { key: "announcement_title", label: "ประกาศ", sortable: true },
        {
          key: "createdAt",
          label: "วันที่สร้าง",
          sortable: true,
        },
        {
          key: "updatedAt",
          label: "แก้ไขล่าสุด",
          sortable: true,
        },
        {
          key: "is_active",
          label: "จัดการ",
        },
      ],
      showModalAnnouncement: false,
      showModalDelete: false,
    }
  },

  methods: {
    onCreateSuccess() {
      this.$refs.announcementTable.refresh();
    },

    onEdit(item) {
      this.editData = { ...item };
      this.showModalAnnouncement = true;
    },

    onDelete(item) {
      this.deleteData = { ...item };
      this.showModalDelete = true;
    },

    onDeleteSuccess() {
      this.$refs.announcementTable.refresh();
    },
  }
}
</script>
