<template>
  <b-form @submit.stop.prevent>
    <div class="d-flex flex-row flex-wrap">
      <div class="col-12 px-2 mb-2">
        <!-- <label class="font-weight-bold" for="announcementTitle">ชื่อแบนเนอร์</label> -->

        <b-form-textarea
          id="announcementTitle"
          :state="invalid ? validation.form.announcement_title.required : null"
          type="text"
          placeholder="เพิ่มประกาศ"
          rows="2"
          max-rows="4"
          :disabled="disabled"
          v-model="form.announcement_title"
        ></b-form-textarea>

        <b-form-invalid-feedback id="announcementTitle-feedback">
          กรุณากรอกข้อมูลประกาศ
        </b-form-invalid-feedback>
      </div>
    </div>

    <div class="d-flex flex-row my-3">
      <div class="col-12 px-2">
        <label class="font-weight-bold ft-s-14" for="bannerImg"
          >ภาพประกาศ</label
        >

        <b-button
          size="sm"
          variant="primary"
          class="mx-2"
          :disabled="disabled"
          @click="toggleAttachFile"
        >
          เลือกไฟล์
        </b-button>

        <small class="text-black-50 ml-2 font-weight-bold"
          >*JPG, PNG ขนาดไม่เกิน 10MB (ขนาดแนะนำ {{ width }} x
          {{ height }} px)</small
        >

        <input
          v-show="false"
          type="file"
          :id="`announcement-image-file`"
          name="addAnnouncementImg"
          accept="image/*"
          ref="addAnnouncementImg"
          @change="previewImage($event)"
        />
        <!-- <div class="w-100 font-weight-bold ft-s-20" for="box9">
          ภาพประกาศ
        </div>

        <div class="d-flex flex-row my-3">
          <div
            class="image-preview d-flex flex-row-warp"
            style="flex-wrap: wrap"
            v-if="form.img_url"
          >
            <div class="wrap-image">
              <button class="close-button" @click="deleteImage()">
                <font-awesome-icon icon="times" class="mx-auto" />
              </button>

              <img
                class="img-thumbnail w-100 h-100"
                :src="form.img_url !== ''  ? form.img_url : form.img_url.raw"
              />
            </div>
          </div>

          <div
            class="image-preview d-flex flex-row-warp"
            style="flex-wrap: wrap; width: 150px;"
            v-if="isProcessing"
          >
            <b-spinner
              v-show="isProcessing"
              label="processing image..."
              class="m-auto"
            >
            </b-spinner>
          </div>

          <button
            class="btn-select-file ft-s-14"
            :disabled="disabled"
            v-if="!form.img_url && !isProcessing"
            @click="toggleAttachFile()"
          >
            <font-awesome-icon icon="plus" class="mx-auto" />
            อัพโหลด
          </button>

          <input
            v-show="false"
            type="file"
            :id="`announcement-image-file`"
            name="addAnnouncementImg"
            accept="image/*"
            multiple
            ref="addAnnouncementImg"
            @change="previewImage($event)"
          />
        </div>

        <div class="d-flex flex-row flex-wrap">
          <small class="text-black-50 ml-2 font-weight-bold ft-s-16">
            *JPG, PNG ขนาดไม่เกิน 20MB (ขนาดแนะนำ {{ width }} x
            {{ height }} px)
          </small>

          <small class="text-danger ml-2 font-weight-bold ft-s-16"
            >*กรณีที่รูปผิด ท่านสามารถลบรูปและเลือกรูปใหม่ และกดบันทึก</small
          >
        </div> -->
      </div>
    </div>

    <carousel-banner
      :removable="!disabled"
      :is-loading="disabled"
      v-model="form"
    />
  </b-form>
</template>

<script>
import Compressor from "compressorjs";
import { Auth } from "../../models";
import CarouselBanner from "../carousel/BannerImage";

export default {
  props: {
    formData: Object,

    editData: {
      type: Object,
      default: () => {},
    },

    disabled: {
      type: Boolean,
      default: false,
    },
    validation: {
      type: Object,
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    disabledInput: {
      type: Object,
      default: () => ({}),
    },
    width: {
      type: Number,
      default: 1366,
    },
    height: {
      type: Number,
      default: 768,
    },
  },

  model: {
    prop: "formData",
    event: "change",
  },

  components: {
    CarouselBanner,
  },

  data() {
    return {
      isProcessing: false,
      form: {
        id: this.editData.id || null,
        announcement_title: this.editData.announcement_title || "",
        key_img: this.editData.key_img || null,
        img_url: this.editData.img_url || "",
      },
    };
  },

  watch: {
    form: {
      deep: true,
      immediate: true,
      handler: "syncData",
    },
  },

  computed: {
    authUser() {
      return Auth.user();
    },
  },

  methods: {
    syncData(v) {
      this.$emit("change", v);
    },

    toggleAttachFile() {
      this.$nextTick(() => {
        const { addAnnouncementImg } = this.$refs;
        addAnnouncementImg.click();
      });
    },

    async previewImage(event) {
      const self = this;
      const { width, height } = this;

      var input = event.target;

      const maxAllowedSize = 20 * 1024 * 1024;

      Array.from(input.files).forEach((file) => {
        if (file.size <= maxAllowedSize) {
          this.isProcessing = true;

          new Compressor(file, {
            quality: 0.8,

            maxWidth: width,

            maxHeight: height,

            resize: "cover",

            // The compression process is asynchronous,
            // which means you have to access the `result` in the `success` hook function.
            success(result) {
              var reader = new FileReader();

              reader.onload = async (e) => {
                // let image = {
                //   file: result,
                //   raw: e.target.result
                // };
                // self.$set(self.form, "img_url", image);
                self.$set(self.form, "file", result);
                self.$set(self.form, "raw", e.target.result);

                self.isProcessing = false;
              };

              reader.readAsDataURL(result);
            },
            error(err) {
              console.log(err.message);
              this.$toast.error("ไม่สามารถอัพโหลดไฟล์ได้ กรุณาลองใหม่อีกครั้ง");
            },
          });
        } else {
          this.$toast.error(
            "ไม่สามารถอัพโหลดไฟล์ขนาดใหญ่เกิน 10 MB ได้ กรุณาลองใหม่อีกครั้ง"
          );
        }
      });

      input.value = "";
    },
    
    deleteImage() {
      this.form.img_url = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.close-button {
  width: 24px;
  height: 24px;
  position: absolute;
  top: -8px;
  right: -8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: transparent;
  background-color: #ffffff;
  border: 1px solid #3d6b99;
  border-radius: 50%;
  padding: 3px;

  &:focus {
    outline: none;
  }
}
.wrap-image {
  position: relative;
  width: 150px;
  height: 150px;

  > img {
    object-fit: cover;
  }
}
.btn-select-file {
  width: 104px;
  height: 104px;
  border: 1px dashed #d9d9d9;
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;

  &:focus {
    outline: 1px dashed #d9d9d9;
  }
}
</style>
