<template>
  <div class="card__flex card card-box mb-3">
    <div class="card-header pr-2">
      <div class="card-header--title">
        <small>{{ title }}</small>
      </div>
      <div class="card-header--actions">
        <slot name="header-actions"></slot>
      </div>
    </div>

    <div class="card-body p-0">
      <div class="h-100 d-flex flex-column justify-content-between">
        <loading v-if="isFetching" :message="loadingMessage" style="height: 310px" />

        <div v-show="!isFetching" class="h-100">
          <b-table
            ref="limitTable"
            :busy.sync="isFetching"
            :items="itemData"
            :fields="fields"
            hover
            responsive
            thead-class="thead-light"
            class="mb-0"
            v-bind="{ ...$attrs, ...$props }"
            v-on="{ ...$listeners }"
          >
            <template #head(limit)="row">
              <div class="text-center">
                <span>{{ row.label }}</span>
              </div>
            </template>

            <template #head(actions)="row">
              <div class="text-center">
                <span>{{ row.label }}</span>
              </div>
            </template>

            <template v-if="showRefresh" #head(refresh)>
              <div class="text-center">
                <a
                  href="javascript:void(0)"
                  class="btn btn-sm text-primary btn-link pl-0"
                  v-b-tooltip.hover
                  title="Refresh"
                  @click.prevent="refresh"
                >
                  <font-awesome-icon :spin="isFetching" size="sm" icon="sync" />
                </a>
              </div>
            </template>

            <template #cell(limit)="{ item }">
              <div class="text-center">
                {{ item.limit }}
              </div>
            </template>

            <template #cell(actions)="{ item }">
              <div class="text-center">
                <b-button size="sm" @click="edit(item)"
                  >แก้ไข</b-button
                >
              </div>
            </template>
          </b-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import formatDateMixin from "../../mixins/formatDateMixin";
import Loading from "../misc/Loading";
import { ConfigTeachingDareLimit } from "../../models";

export default {
  mixins: [formatDateMixin],

  props: {
    title: String,
    fields: Array,
    params: Object,
  },

  components: {
    Loading,
  },

  data() {
    return {
      isFetching: false,
      // currentPage: parseInt(this.$route.query.page) || 1,
      // numberOfPages: 1,
      // sortBy: "",
      // sortDesc: false,
      showRefresh: true,
      loadingMessage: "กำลังดาวน์โหลดข้อมูล",
      itemData: [],
      // fieldsConfig: [
      //   { key: "name", label: "ตั้งค่า" },
      //   { key: "limit", label: "จำนวนแผนการสอน" },
      //   { key: 'actions', label: 'จัดการ' },
      //   { key: "refresh", label: "", thStyle: "width: 25px;" },
      // ],
    };
  },

  watch: {
    params: {
      deep: true,
      handler: "onParamsChanged",
    },
  },

  methods: {
    async fetch() {
      let data = {}

      this.isFetching = true;

      try {
        const promise = await ConfigTeachingDareLimit.api().findAll({ save: false });

        let { data: responseData = []} =
          promise.response.data;
        data = {
          ...responseData,
          name: "ตั้งค่าจำนวนแผนการสอน",
        };
        this.itemData.push(data);
      } catch (error) {
        console.log(error);
        this.$toast.error("ไม่สามารถดึงข้อมูลได้ กรุณาลองใหม่อีกครั้ง");
      } finally {
        this.isFetching = false;
      }

      return data;
    },

    edit(item) {
      this.$emit("edit", item);
    },

    refresh() {
      // this.$emit("before:refresh");

      this.$refs.limitTable.refresh();

      // this.$emit("after:refresh");
    },
  },

  created() {
    this.fetch();
  },
};
</script>
