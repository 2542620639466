<template>
  <page-content>
    <page-title heading="รายงานภาพรวมผลการประเมิน"> </page-title>

    <div>
      <b-row align-v="center" align-h="end" class="mb-4">
        <b-col cols="12">
          <filter-fiscal-year-term v-model="formFiscalYearTerm">
          </filter-fiscal-year-term>

          <filter-master
            class="mt-2"
            v-model="formfilter"
            col-headquarter="4"
            col-division="4"
            col-station="4"
            :hiddenInput="{ school: true, schoolClass: true, room: true }"
          >
          </filter-master>

          <filter-date-between
            v-model="filterDateBetween"
            :col-start-date="4"
            :col-end-date="4"
          >
          </filter-date-between>
        </b-col>
      </b-row>

      <b-row v-if="isFetching" align-h="center">
        <loading
          style="height: 250px"
          message="กำลังดาวน์โหลดสรุปภาพรวมผลการประเมิน"
        />
      </b-row>

      <b-row v-else>
        <b-col cols="12">
          <survey-chart :survey_answers="survey_answers"> </survey-chart>
        </b-col>
      </b-row>
    </div>
  </page-content>
</template>

<script>
import PageContent from "../../components/layout/PageContent";
import PageTitle from "../../components/layout/PageTitle";
import SurveyChart from "../../components/chart/Survey";
import { debounce, mapKeys, chain } from "lodash";
import { Survey } from "../../models";
import FilterMaster from "../../components/form/FilterMaster";
import FilterDateBetween from "../../components/form/FilterDateBetween";
import FilterFiscalYearTerm from "../../components/form/FilterFiscalYearTerm";
import Loading from "../../components/misc/Loading";

const defaultValue = "ทั้งหมด";

export default {
  components: {
    PageContent,
    PageTitle,
    SurveyChart,
    FilterMaster,
    FilterDateBetween,
    FilterFiscalYearTerm,
    Loading,
  },

  data() {
    return {
      survey_id: 13,
      survey_answers: [],
      isFetching: false,
      formfilter: {},
      filterDateBetween: {
        startDate: null,
        endDate: null,
      },
      formFiscalYearTerm: {
        fiscalYearFrom: defaultValue,
        termFrom: defaultValue,
        fiscalYearTo: defaultValue,
        termTo: defaultValue,
      },
    };
  },

  watch: {
    formfilter: {
      deep: true,
      handler: "onRouteOrFilterChanged",
    },

    filterDateBetween: {
      deep: true,
      handler: "onFilterDateBetweenChanged",
    },

    formFiscalYearTerm: {
      deep: true,
      handler: "onFormFiscalYearTermChanged",
    },
  },

  methods: {
    async onRouteOrFilterChanged() {
      await this.fetch();
    },

    async onFilterDateBetweenChanged() {
      await this.fetch();
    },

    async onFormFiscalYearTermChanged() {
      await this.fetch();
    },

    getFilterTermYear() {
      let params = {};

      if (
        this.formFiscalYearTerm.fiscalYearFrom &&
        this.formFiscalYearTerm.fiscalYearFrom !== defaultValue
      ) {
        this.$set(
          params,
          "fiscalYearFrom",
          this.formFiscalYearTerm.fiscalYearFrom
        );
      }

      if (
        this.formFiscalYearTerm.termFrom &&
        this.formFiscalYearTerm.termFrom !== defaultValue
      ) {
        this.$set(params, "termFrom", this.formFiscalYearTerm.termFrom);
      }

      if (
        this.formFiscalYearTerm.fiscalYearTo &&
        this.formFiscalYearTerm.fiscalYearTo !== defaultValue
      ) {
        this.$set(params, "fiscalYearTo", this.formFiscalYearTerm.fiscalYearTo);
      }

      if (
        this.formFiscalYearTerm.termTo &&
        this.formFiscalYearTerm.termTo !== defaultValue
      ) {
        this.$set(params, "termTo", this.formFiscalYearTerm.termTo);
      }

      return params;
    },

    async fetch() {
      let promise;
      let params = {};
      this.survey_answers = [];
      const {
        mHeadquarterId = null,
        mDivisionId = null,
        mStationId = null,
        mSchoolId = null,
      } = this.formfilter;

      const { startDate, endDate } = this.filterDateBetween;

      this.noData = false;
      this.isFetching = true;

      this.$set(params, "survey_id", this.survey_id);

      if (mHeadquarterId) {
        this.$set(params, "mHeadquarterId", mHeadquarterId);
      }

      if (mDivisionId) {
        this.$set(params, "mDivisionId", mDivisionId);
      }

      if (mStationId) {
        this.$set(params, "mStationId", mStationId);
      }

      if (mSchoolId) {
        this.$set(params, "mSchoolId", mSchoolId);
      }

      if (startDate) {
        this.$set(params, "startDate", startDate);
      }

      if (endDate) {
        this.$set(params, "endDate", endDate);
      }

      try {
        promise = await Survey.api().getDashboard({
          ...params,
          ...this.getFilterTermYear(),
        });

        const { data = [] } = promise.response.data;

        if (data && data.length > 0) {
          for (let index = 0; index < data.length; index++) {
            const element = data[index];
            let { questions } = element;

            let tmp_questions = await questions.filter(
              (question) => question.question_type !== "text"
            );

            tmp_questions.sort(function (a, b) {
              var question_no_A = a.question_no,
                question_no_B = b.question_no;
              if (question_no_A < question_no_B) return -1;
              if (question_no_A > question_no_B) return 1;
              return 0;
            });

            let tmpQuestionsAll;

            let newQuestions = tmp_questions.filter(function (question) {
              return question.surveyTopicId == null;
            });

            let tmpQuestions = mapKeys(newQuestions, function (o) {
              return o.question_no;
            });

            let newQuestionsHaveSurveyTopic = tmp_questions.filter(function (
              question
            ) {
              return question.surveyTopicId !== null;
            });

            if (newQuestionsHaveSurveyTopic) {
              let groupBy_newQuestionsHaveSurveyTopic = chain(
                newQuestionsHaveSurveyTopic
              )
                .groupBy("survey_topic.survey_topic_no")
                .map((value, key) => ({ survey_topic: key, questions: value }))
                .value();

              let tmpSurveyTopics = mapKeys(
                groupBy_newQuestionsHaveSurveyTopic,
                function (o) {
                  return o.survey_topic;
                }
              );

              tmpQuestionsAll = { ...tmpQuestions, ...tmpSurveyTopics };
            } else {
              tmpQuestionsAll = { ...tmpQuestions };
            }

            this.survey_answers.push({
              ...element,
              questions: tmpQuestionsAll,
            });
          }
        } else {
          this.noData = true;
        }
      } catch (error) {
        this.$toast.error(
          "ไม่สามารถดึงสรุปข้อมูลแบบประเมินได้ กรุณาลองใหม่อีกครั้ง"
        );
      } finally {
        this.isFetching = false;
      }

      return promise;
    },
  },

  created() {
    this.fetch = debounce(this.fetch, 500);
  },
  
  mounted() {
    this.fetch();
  },
};
</script>