<template>
  <page-content>
    <page-title heading="ตั้งค่าแผนการสอน"></page-title>

    <b-row class="mt-4">
      <b-col cols="12">
        <b-row align-v="end" align-h="between">
          <b-col>
            <h5 class="mb-0">ตั้งค่าการอัพโหลดรูปแผนการสอน</h5>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <config-upload-image-table
      ref="configUploadTable"
      class="my-4"
      :fields="configUploadFields"
      :params="configUploadTableParams"
    />

    <b-row class="mt-3">
      <b-col cols="12">
        <b-row align-v="end" align-h="between">
          <b-col>
            <h5 class="mb-0">ตั้งค่าปีการศึกษา</h5>
          </b-col>
          <b-col class="text-right">
            <b-button variant="danger" @click="showModalMSemester = true"
              >เพิ่มปีการศึกษา</b-button
            >
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-row class="mt-3 row-content">
      <b-col cols="4">
        <p class="mb-0">ตั้งค่าปีการศึกษาปัจจุบัน</p>
      </b-col>
      <b-col cols="8">
        <b-form-select
          v-model="currentSemester"
          :options="semesterOptions"
        ></b-form-select>
      </b-col>
    </b-row>

    <config-m-semester-table
      ref="configSemesterTable"
      class="my-4"
      :fields="configMSemesterFields"
      :params="configMSemesterTableParams"
      @edit="onEditMSemester"
      @delete="onDeleteMSemester"
    />

    <create-or-update-m-semester
      :edit-data.sync="mSemesterEditData"
      v-model="showModalMSemester"
      @create:success="onCreateSemesterSuccess"
    ></create-or-update-m-semester>

    <b-row class="mt-4">
      <b-col cols="12">
        <b-row align-v="end" align-h="between">
          <b-col>
            <h5 class="mb-0">ตั้งค่าการลงแผนการสอน</h5>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <config-lesson-plan-table
      ref="configLessonPlanTable"
      class="my-4"
      :fields="configUploadFields"
      :params="configUploadTableParams"
    />

    <b-row class="mt-4">
      <b-col cols="12">
        <b-row align-v="end" align-h="between">
          <b-col>
            <h5 class="mb-0">ตั้งค่าจำนวนแผนการสอน</h5>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <config-teaching-dare-limit-table
      ref="configTeachingDareLimitTable"
      class="my-4"
      :fields="configLimitFields"
      :params="configLimitTableParams"
      @edit="onEditTeachingDareLimit"
    />

    <update-teaching-dare-limit
      :edit-data.sync="teachingDareLimitData"
      v-model="showModalTeachingDareLimit"
      @update:success="onUpdateLimitSuccess"
    ></update-teaching-dare-limit>
  </page-content>
</template>

<script>
import PageContent from "../../components/layout/PageContent";
import PageTitle from "../../components/layout/PageTitle";
import ConfigUploadImageTable from "../../components/table/ConfigUploadImage";
import ConfigMSemesterTable from "../../components/table/ConfigMSemester";
import CreateOrUpdateMSemester from "../../components/modal/CreateOrUpdateMSemester";
import ConfigLessonPlanTable from "../../components/table/ConfigLessonPlan";
import ConfigTeachingDareLimitTable from "../../components/table/ConfigTeachingDareLimit.vue";
import UpdateTeachingDareLimit from "../../components/modal/UpdateTeachingDareLimit.vue";
import { MSemester } from "../../models";

export default {
  components: {
    PageContent,
    PageTitle,
    ConfigUploadImageTable,
    ConfigMSemesterTable,
    CreateOrUpdateMSemester,
    ConfigLessonPlanTable,
    ConfigTeachingDareLimitTable,
    UpdateTeachingDareLimit,
  },

  data() {
    return {
      mSemesterEditData: {},
      mSemesterDeleteData: {},
      teachingDareLimitData: {},
      showModalMSemester: false,
      showModalDeleteMSemester: false,
      showModalTeachingDareLimit: false,
      configUploadTableParams: {},
      configUploadFields: ["ตั้งค่า"],
      configMSemesterTableParams: {},
      configMSemesterFields: [
        { key: "fiscal_year", label: "ปีการศึกษา" },
        { key: "term", label: "เทอม" },
        // {
        //   key: "createdAt",
        //   label: "วันที่สร้าง",
        //   sortable: true,
        // },
        // {
        //   key: "updatedAt",
        //   label: "แก้ไขล่าสุด",
        //   sortable: true,
        // },
      ],
      configLessonPlanTableParams: {},
      configLessonPlanFields: ["ตั้งค่า"],
      configLimitTableParams: {},
      configLimitFields: [
        { key: "name", label: "ตั้งค่า" },
        { key: "limit", label: "จำนวนแผนการสอน" },
        { key: "actions", label: "จัดการ" },
        { key: "refresh", label: "", thStyle: "width: 25px;" },
      ],
      currentSemester: "",
      semesterOptions: [],
    };
  },

  watch: {
    currentSemester: {
      handler: "onCurrentSemesterChanged",
    },
  },

  methods: {
    async fetchCurrentSemester() {
      let data = {};

      try {
        const promise = await MSemester.api().findCurentSemester();

        let { data: responseData = {} } = promise.response.data;

        data = responseData?.id || null;
      } catch (error) {
        console.log(error);
        this.$toast.error(
          "ไม่สามารถดึงข้อมูลปีการศึกษาปัจจุบันได้ กรุณาลองใหม่อีกครั้ง"
        );
      }

      this.currentSemester = data;
    },

    async fetchSemesterOptions() {
      let data = [];

      try {
        const promise = await MSemester.api().findAll();

        let { data: responseData = [] } = promise.response.data;

        data = responseData.map((record) => {
          return {
            ...record,
            value: record.id,
            text:
              record.fiscal_year !== null
                ? `${record.fiscal_year}/${record.term}`
                : `${record.term}`,
          };
        });
      } catch (error) {
        console.log(error);
        this.$toast.error(
          "ไม่สามารถดึงข้อมูลตัวเลือกปีการศึกษาปัจจุบันได้ กรุณาลองใหม่อีกครั้ง"
        );
      }

      this.semesterOptions = data;
    },

    async onCurrentSemesterChanged(selected_semester = null) {
      try {
        const currentRes = await MSemester.api().findCurentSemester();

        let { data: responseData = {} } = currentRes.response.data;

        if (
          (responseData === null && selected_semester !== null) ||
          (!!responseData && selected_semester !== responseData?.id)
        ) {
          const promise = await MSemester.api().updateCurentSemester(
            selected_semester
          );

          let { data } = promise.response;

          if (data && data.response_status == "SUCCESS") {
            this.$toast.success("อัพเดตข้อมูลปีการศึกษาปัจจุบันสำเร็จ");
          } else {
            this.$toast.error(
              "แก้ไขปีการศึกษาปัจจุบันไม่สำเร็จ กรุณาลองใหม่อีกครั้ง"
            );
          }
        }
      } catch (error) {
        console.log(error);
        this.$toast.error(
          "ไม่สามารถอัพเดตข้อมูลปีการศึกษาปัจจุบันได้ กรุณาลองใหม่อีกครั้ง"
        );
      }
    },

    onCreateSemesterSuccess() {
      this.$refs.configSemesterTable.refresh();
    },

    onUpdateLimitSuccess() {
      // this.$refs.configTeachingDareLimitTable.refresh();
      window.location.reload();
    },

    onEditMSemester(item) {
      this.mSemesterEditData = { ...item };
      this.showModalMSemester = true;
    },

    onDeleteMSemester(item) {
      this.mSemesterDeleteData = { ...item };
      this.showModalDeleteMSemester = true;
    },

    onEditTeachingDareLimit(item) {
      this.teachingDareLimitData = { ...item };
      this.showModalTeachingDareLimit = true;
    },
  },

  mounted() {
    this.fetchCurrentSemester();
    this.fetchSemesterOptions();
  },
};
</script>

<style lang="scss" scoped>
.row-content {
  margin: 16px 0;
  padding: 12px 0;
  border-radius: 16px;
  border: 0.2rem solid #3d6b99;
  align-items: center;

  /* --angle: 0deg;
  width: 100%;
	height: 100%;
	border: 0.5rem solid;
	border-image: conic-gradient(from var(--angle), red, yellow, lime, aqua, blue, magenta, red) 1;
	
	animation: 3s rotate linear infinite; */
}

@keyframes rotate {
  to {
    --angle: 360deg;
  }
}

@property --angle {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}
</style>
