<template>
  <div class="d-flex flex-row flex-wrap">
    <b-col
      v-if="!hiddenInput.fiscalYearFrom"
      :lg="colFiscalYearFrom"
      class="px-2"
    >
      <label class="font-weight-bold ft-s-14" for="fiscalYearFrom">{{
        labelFiscalYearFrom
      }}</label>

      <!-- <b-form-input
              id="fiscalYearFrom"
              v-model="fiscalYearFrom"
            ></b-form-input> -->
      <v-select
        id="fiscalYearFrom"
        v-model="form.fiscalYearFrom"
        :options="fiscalYears"
        :loading="isFetching"
        :clearable="isNotDefaultValue(form.fiscalYearFrom)"
        @input="onInput($event, 'fiscalYearFrom')"
      ></v-select>
    </b-col>

    <b-col v-if="!hiddenInput.termFrom" :lg="colTermFrom" class="px-2">
      <label class="font-weight-bold ft-s-14" for="termFrom">{{
        labelTermFrom
      }}</label>

      <v-select
        id="termFrom"
        v-model="form.termFrom"
        :options="termsFrom"
        :disabled="termsFrom.length < 1"
        :clearable="isNotDefaultValue(form.termFrom)"
        @input="onInput($event, 'termFrom')"
      ></v-select>
    </b-col>

    <b-col v-if="!hiddenInput.fiscalYearTo" :lg="colFiscalYearTo" class="px-2">
      <label class="font-weight-bold ft-s-14" for="fiscalYearTo">{{
        labelFiscalYearTo
      }}</label>

      <!-- <b-form-input v-model="fiscalYearTo"></b-form-input> -->
      <v-select
        id="fiscalYearTo"
        v-model="form.fiscalYearTo"
        :options="fiscalYears"
        :loading="isFetching"
        :clearable="isNotDefaultValue(form.fiscalYearTo)"
        @input="onInput($event, 'fiscalYearTo')"
      ></v-select>
    </b-col>

    <b-col v-if="!hiddenInput.termTo" :lg="colTermTo" class="px-2">
      <label class="font-weight-bold ft-s-14" for="termTo">{{
        labelTermTo
      }}</label>

      <v-select
        id="termTo"
        v-model="form.termTo"
        :options="termsTo"
        :disabled="termsTo.length < 1"
        :clearable="isNotDefaultValue(form.termTo)"
        @input="onInput($event, 'termTo')"
      ></v-select>
    </b-col>

    <slot></slot>
  </div>
</template>

<script>
import vSelect from "vue-select";
import { MSemester } from "../../models";

// const currentYear = new Date().getFullYear() + 543;

export default {
  props: {
    vModel: Object,

    defaultValue: {
      type: String,
      default: "ทั้งหมด",
    },

    hiddenInput: {
      type: Object,
      default: () => ({}),
    },

    colFiscalYearFrom: {
      type: Number,
      default: 3,
    },

    colTermFrom: {
      type: Number,
      default: 3,
    },

    colFiscalYearTo: {
      type: Number,
      default: 3,
    },

    colTermTo: {
      type: Number,
      default: 3,
    },

    labelFiscalYearFrom: {
      type: String,
      default: "เริ่มปีการศึกษา",
    },

    labelTermFrom: {
      type: String,
      default: "ภาคเรียน",
    },

    labelFiscalYearTo: {
      type: String,
      default: "ถึงปีการศึกษา",
    },

    labelTermTo: {
      type: String,
      default: "ภาคเรียน",
    },

    fiscalYearProvider: {
      type: Function,
    },

    isTermFromMultiple: {
      type: Boolean,
      default: true,
    },
  },

  model: {
    prop: "vModel",
    event: "change",
  },

  components: {
    vSelect,
  },

  data() {
    return {
      isFetching: false,
      // terms: [
      //   this.defaultValue,
      //   "1",
      //   "2",
      //   "เทอม 1 รอบเก็บตก",
      //   "เทอม 2 รอบเก็บตก",
      //   // "เก็บตกเทอม 1",
      // ],
      // fiscalYears: [
      //   this.defaultValue,
      //   currentYear - 10,
      //   currentYear - 9,
      //   currentYear - 8,
      //   currentYear - 7,
      //   currentYear - 6,
      //   currentYear - 5,
      //   currentYear - 4,
      //   currentYear - 3,
      //   currentYear - 2,
      //   currentYear - 1,
      //   currentYear,
      //   currentYear + 1,
      //   currentYear + 2,
      //   currentYear + 3,
      //   currentYear + 4,
      //   currentYear + 5,
      // ],
      fiscalYears: [],
      defaultSemester: [],
    };
  },

  computed: {
    form: {
      get() {
        return this.vModel;
      },

      set(v) {
        this.$emit("change", v);
      },
    },

    // fiscalYears() {
    //   const { defaultSemester = [] } = this;

    //   const fiscalYears = defaultSemester
    //     .map((record) => {
    //       return record.fiscal_year;
    //     });

    //   return [this.defaultValue, ...new Set(fiscalYears)];
    // },

    termsFrom() {
      const { form, defaultSemester = [] } = this;
      
      let terms = [];

      if (form.fiscalYearFrom) {
        const filteredActiveSemesters = defaultSemester.filter(({ fiscal_year }) => form.fiscalYearFrom == fiscal_year);

        if (filteredActiveSemesters.length) {
          const filteredTerms = filteredActiveSemesters.map(({ term }) => term);

          terms = [this.defaultValue, ...new Set(filteredTerms)];
        }
      }
      return terms;
    },

    termsTo() {
      const { form, defaultSemester = [] } = this;
      
      let terms = [];

      if (form.fiscalYearTo) {
        const filteredActiveSemesters = defaultSemester.filter(({ fiscal_year }) => form.fiscalYearTo == fiscal_year);

        if (filteredActiveSemesters.length) {
          const filteredTerms = filteredActiveSemesters.map(({ term }) => term);

          terms = [this.defaultValue, ...new Set(filteredTerms)];
        }
      }
      return terms;
    },
  },

  methods: {
    isNotDefaultValue(value) {
      return value !== this.defaultValue;
    },

    async fetchSemesterOptions() {
      let data = [];
      let yearData = [];

      try {
        const promise = await MSemester.api().findAll();

        let { data: responseData = [] } = promise.response.data;

        data = responseData
          .sort((a, b) => (a.index > b.index ? 1 : b.index > a.index ? -1 : 0))
          .filter((record) => record.fiscal_year !== null)
          .map((record) => {
            if (record.fiscal_year) {
              yearData.push(record.fiscal_year);
            }
            return record;
          });
      } catch (error) {
        console.log(error);
        this.$toast.error(
          "ไม่สามารถดึงข้อมูลตัวเลือกปีการศึกษาปัจจุบันได้ กรุณาลองใหม่อีกครั้ง"
        );
      }

      this.defaultSemester = data;
      this.fiscalYears = [this.defaultValue, ...new Set(yearData)];
    },

    onInput(value, dataName) {
      // if (dataName == "termFrom" && this.isTermFromMultiple) {
      //   let lt = [...value].pop();
      //   if (lt != "ทั้งหมด") {
      //     this.form.termFrom = this.form.termFrom.filter(
      //       (item) => item != "ทั้งหมด"
      //     );
      //   } else if (lt) {
      //     this.form.termFrom = ["ทั้งหมด"];
      //   }
      // }

      // if (dataName == "termFrom" && !this.isTermFromMultiple) {
      //   this.$set(this.form, dataName, value)
      // }

      // if (dataName == "termTo") {
      //   let lt = [...value].pop();
      //   if (lt != "ทั้งหมด") {
      //     this.form.termTo = this.form.termTo.filter(
      //       (item) => item != "ทั้งหมด"
      //     );
      //   } else if (lt) {
      //     this.form.termTo = ["ทั้งหมด"];
      //   }
      // }

      if (dataName == "fiscalYearFrom" && !this.isTermFromMultiple) {
        this.$set(this.form, dataName, value)
      }

      if (!value) {
        this.$set(this.form, dataName, this.defaultValue);
      }
    },
  },

  mounted() {
    this.fetchSemesterOptions();
  },

  async created() {
    if (this.fiscalYearProvider) {
      this.isFetching = true;

      this.fiscalYears = await this.fiscalYearProvider();

      this.isFetching = false;
    }
  },
};
</script>
